









































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { CreatePatient, SearchPatient } from "@/request/storehouse";
import { getTopicOpt } from "@/request/topic";
import Agree from "../agree/agree.vue";
import AES from "@/utils/AES";
@Component({
  components: {
    Agree,
  },
})
export default class Name extends Vue {
  @Prop({
    type: Boolean,
    default: true,
  })
  private ifSave: any; // 点击保存按钮时是否保存，false时不保存把数据传回父组件
  @Prop()
  private ifShow: any;
  private ifShowAgree: any = false;
  private patient: any = {
    姓名: "",
    性别: "",
    身份证号: "",
    出生日期: "",
    联系方式: "",
  };
  private nameOptions: any = [];
  private topicOpt: any = [];
  private get center() {
    return this.$store.state.center;
  }
  private get user() {
    return this.$store.state.user;
  }
  private get configuration() {
    return this.$store.state.configuration;
  }
  private get visible() {
    return this.ifShow;
  }
  private set visible(val) {
    this.$emit("cancel", val);
  }
  private cancel() {
    this.$emit("cancel", false);
  }
  private openAgree() {
    if (this.configuration.agreePage) {
      this.ifShowAgree = true;
    }
  }
  private nameChange(e: any) {
    this.patient["patient_id"] = e["patient_id"];
    this.patient["姓名"] = e["姓名"];
    this.patient["性别"] = e["性别"];
    this.patient["出生日期"] = e["出生日期"];
    this.patient["身份证号"] = e["身份证号"];
    this.patient["受试者编号"] = e["受试者编号"];
    this.patient.card = e.card;
  }
  private onShenfenzhengBlur(e: any) {
    if (this.patient["出生日期"]) {
      return;
    }
    const strId = this.patient["身份证号"].toString();
    if (strId.length !== 18) {
      return;
    }
    const year = Number(strId.substr(6, 4));
    let month: any = Number(strId.substr(10, 2));
    let day: any = Number(strId.substr(12, 2));
    if (year === NaN || month === NaN || day === NaN) {
      return;
    }
    if (year < 1900 || year > 2200) {
      return;
    }
    if (month < 1 || month > 12) {
      return;
    }
    if (day < 1 || day > 31) {
      return;
    }
    if ([1, 3, 5, 7, 8, 10, 12].indexOf(month) == -1) {
      if (day > 30) {
        return;
      }
    }
    if (year % 4 !== 0 && month === 2 && day > 28) {
      return;
    }
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    this.patient["出生日期"] = "" + year + "-" + month + "-" + day;
  }
  private remoteMethod(val: any) {
    this.patient["姓名"] = val;
    const params = {
      params: {
        project_id: this.center.project_id,
        search: val,
      },
    };
    SearchPatient(this, params).then((res: any) => {
      res.forEach((item: any) => {
        item.text = "";
        if (item["姓名"]) {
          item.text = item["姓名"] + "-";
        }
        if (item["性别"]) {
          item.text += item["性别"] + "-";
        }
        if (item["年龄"]) {
          item.text += item["年龄"] + "岁-";
        }
        if (item["身份证号"]) {
          item.text += item["身份证号"];
        }
        if (item["标签"]) {
          item.text += "(" + item["标签"] + ")";
        }
      });
      this.nameOptions = res;
    });
  }
  /**
   * @description 保存
   */
  private save() {
    // var encrypts = AES.encrypt(this.patient["身份证号"]);
    // var dess = AES.decrypt(encrypts);
    if (!this.patient["姓名"]) {
      this.$message.warning(this.configuration.nameText + "必须填写");
      return;
    }
    if (!this.patient["身份证号"]) {
      this.$message.warning("认证号必须填写");
      return;
    }
    if (this.configuration.name == "shuguang" && !this.patient["select"]) {
      this.$message.warning("请同意签署知情同意书");
      return;
    }
    const birthdayRep: RegExp = /\d{4}-[0-1]\d-\d{2}/gi;
    if (this.patient["出生日期"]) {
      if (!birthdayRep.test(this.patient["出生日期"])) {
        this.$message.warning("出生日期格式不对，请重新输入");
        return;
      }
    }
    // 验证手机号格式
    const shoujiRep: RegExp = /^\d{11}$/;
    // if (this.patient["认证方式"] === "联系方式") {
    //   if (!shoujiRep.test(this.patient["身份证号"])) {
    //     this.$message.warning("认证号码格式错误，请输入11位数！");
    //     return;
    //   }
    // }
    // 验证身份证格式
    const shenfenzhengRep: RegExp = /^[a-zA-Z0-9]{18}$/;
    // if (this.patient["认证方式"] === "身份证号") {
    //   if (!shenfenzhengRep.test(this.patient["身份证号"])) {
    //     this.$message.warning("认证号码格式错误，请输入18位数字或字母！");
    //     return;
    //   }
    // }
    this.patient.group_id = this.center.group_id;
    this.patient.project_id = this.center.project_id;
    const obj: any = JSON.parse(JSON.stringify(this.patient));
    if (obj.patient_id) {
      obj["身份证号"] = this.patient.card;
    } else {
      obj["身份证号"] = AES.encrypt(this.patient["身份证号"]);
    }
    if (this.ifSave) {
      CreatePatient(this, obj).then((data: any) => {
        this.$emit("change");
        this.cancel();
      });
    } else {
      this.$emit("change", obj);
    }
  }
  private mounted() {
    getTopicOpt(this).then((res: any) => {
      this.topicOpt = res;
    });
  }
}
